* {
  --white: #ffffff !important;
  --primary-color: #0f66b0 !important;
  --secondary-color: #75abd9 !important;
  --thirty-color: #f5f5f5 !important;
  --fourth-color: #d8d8d8 !important;
  font-family: "Nunito", sans-serif;
  box-sizing: border-box;
  font-size: 16px !important;
}

.transparent {
  background-color: transparent !important;
  color: var(--black) !important;
}

.break-word {
  word-break: break-word;
}

.rdw-editor-toolbar,
.rdw-editor-main {
  border-radius: 12px !important;
  background-color: #ffffff;
}

.tui-image-editor-container .tui-image-editor-controls-logo > img,
.tui-image-editor-container .tui-image-editor-header-logo > img {
  display: none !important;
}

.tui-image-editor-container .tui-image-editor-header-buttons,
.tui-image-editor-container .tui-image-editor-controls-buttons {
  display: none !important;
}

.shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.ft-14 {
  font-size: 14px !important;
}

.ft-12 {
  font-size: 12px !important;
}

.w-50 {
  width: 50%;
}

.pr-40 {
  padding-right: 40px !important;
}

.pr-80 {
  padding-right: 80px !important;
}

.pr-120 {
  padding-right: 120px !important;
}

.d-inline-flex {
  display: inline-flex;
}

.ignore-flex {
  flex-grow: 0;
  flex-shrink: 0;
}

.p-40 {
  padding: 40px !important;
}

.bg-blue-extra-light {
  background-color: #eff5f9;
}

.bg-fourth-color {
  background-color: var(--fourth-color) !important;
}

.text-bold {
  font-weight: bold;
}

.w-50 {
  width: 50%;
}

.rdw-editor-main {
  padding: 10px;
  border: 1px solid #f1f1f1;
  min-height: 200px;
}

.rotate {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

textarea {
  resize: none;
}

.opacity {
  transition: 0.3s;
}

.opacity:hover {
  opacity: 0.8;
}

.opacity:active {
  opacity: 0.6;
}

.ql-editor {
  font-size: 16px;
}

.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "16px" !important;
  font-size: 16px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
  content: "10px" !important;
  font-size: 10px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  content: "12px" !important;
  font-size: 12px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: "14px" !important;
  font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: "16px" !important;
  font-size: 16px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: "18px" !important;
  font-size: 18px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  content: "20px" !important;
  font-size: 20px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before {
  content: "22px" !important;
  font-size: 22px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before {
  content: "16px" !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="10px"]::before {
  content: "10px" !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before {
  content: "12px" !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before {
  content: "14px" !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before {
  content: "16px" !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before {
  content: "18px" !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before {
  content: "20px" !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="22px"]::before {
  content: "22px" !important;
}

.ql-snow {
  border-radius: 8px !important;
}

.ql-toolbar {
  margin-bottom: 8px;
  background-color: var(--secondary-color);
  border-color: var(--secondary-color) !important;
}

.ql-container {
  border-top: 1px solid #ccc !important;
}

.ql-editor {
  min-height: 120px;
}

.overflowY-auto {
  overflow-y: auto;
}

.h-px-900 {
  height: 900px;
}
